const getPagesForProductVariantBySize = (productVariant?: {
  pageNames?: string[] | null;
  pagesForAspectRatios?: Record<string, string[]> | null;
}, size?: string | null) => {
  if (!productVariant) {
    return [];
  }

  const pagesForAspectRatios = productVariant.pagesForAspectRatios as Record<string, string[]> | null | undefined;
  const pages = ((pagesForAspectRatios
  && Object.keys(pagesForAspectRatios).length > 0
    && Object.entries(pagesForAspectRatios).find(
      ([ aspectRatio ]) => {
        const pageRatio = aspectRatio.split(':').reduce((acc, curr) => parseInt(curr, 10) / acc, 1);
        const activeRatio = size?.split('x').reduce((acc, curr) => parseInt(curr, 10) / acc, 1);
        return activeRatio && (pageRatio === activeRatio || pageRatio === (1 / activeRatio));
      },
    )?.[1]
  )
      || productVariant.pageNames) ?? [];

  return pages;
};

export { getPagesForProductVariantBySize };
