<script setup lang="ts">
import { EyeOffIcon } from 'lucide-vue-next';

withDefaults(defineProps<{
  iconSmall?: boolean;
  showButton?: boolean;
}>(), { iconSmall: false,showButton: false, })

const { setIsMatureContentEnabled } = useMatureContent();

const translationKeys = { matureButton: 'common.matureButton' };
</script>

<template>
  <div class="absolute z-20 w-max">
    <div
      class="flex items-center justify-center"
      :class="{'mb-2.5 md:mb-6': showButton}"
    >
      <EyeOffIcon :class="[iconSmall ? 'size-5' : 'size-11']" />
    </div>
    <Button
      v-if="showButton"
      class="rounded-full border-transparent md:px-4 md:py-2"
      variant="secondary"
      @click.prevent="setIsMatureContentEnabled(true)"
    >
      <div
        v-t="translationKeys.matureButton"
        class="text-xs"
      />
    </Button>
  </div>
</template>
