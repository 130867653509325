<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><rect
    height="9"
    opacity="0.1"
    rx="1.34"
    width="7"
    x="8.5"
    y="7.14"
  /><path d="M20.4,1.22A9.84,9.84,0,0,0,16.47,0,36.61,36.61,0,0,1,12,.62,36.61,36.61,0,0,1,7.53,0,9.84,9.84,0,0,0,3.6,1.22L0,4.22,1.47,8.27a1.63,1.63,0,0,0,1.47.89A1.72,1.72,0,0,0,3.6,9l.87-.38s.56,2.06-.83,7.18c0,0,.22,4.66,1.07,6S7.32,24,12,24s6.44-.84,7.29-2.17,1.07-6,1.07-6c-1.39-5.12-.83-7.18-.83-7.18L20.4,9a1.72,1.72,0,0,0,.66.13,1.63,1.63,0,0,0,1.47-.89l1.47-4ZM18,21l-.18.23-1.59-1.76,2.45-1.87A12,12,0,0,1,18,21Zm.74-5.33-4.9,3.73H10.14L5.23,15.69a23.43,23.43,0,0,0,.85-6s0-.08,0-.13A13,13,0,0,1,7.79,3,4.76,4.76,0,0,0,12,5.31,4.55,4.55,0,0,0,16.12,3,12.49,12.49,0,0,1,17.9,9.53a.53.53,0,0,0,0,.12A22.52,22.52,0,0,0,18.77,15.69ZM6,21.09,6,21a12,12,0,0,1-.68-3.4l2.29,1.75ZM15,1.78a3.09,3.09,0,0,1-3,2,3.3,3.3,0,0,1-3.14-2A22.25,22.25,0,0,0,12,2.12,22.11,22.11,0,0,0,15,1.78ZM4.47,7.15a1.52,1.52,0,0,0-.59.12L3,7.65H2.94a.13.13,0,0,1-.1,0L1.77,4.7,4.49,2.43a7.19,7.19,0,0,1,2.37-.82A13.54,13.54,0,0,0,4.78,7.18,1.55,1.55,0,0,0,4.47,7.15ZM7.28,21.94,8.76,20.3l0,0,.65.5a.74.74,0,0,0,.45.15h4.24a.74.74,0,0,0,.45-.15l.49-.37L16.52,22A15.23,15.23,0,0,1,12,22.5,14.31,14.31,0,0,1,7.28,21.94ZM21.16,7.63a.13.13,0,0,1-.1,0H21l-.88-.38a1.52,1.52,0,0,0-.59-.12,1.73,1.73,0,0,0-.32,0A13,13,0,0,0,17,1.59a7.32,7.32,0,0,1,2.5.84L22.23,4.7Z" /></svg>
</template>
