<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="m18.07,9.72l-6.25,3.9c-.71.5-1.61.5-2.22,0l-3.93-2.8c-1.41-.9-1.41-1.6-.71-2.1l5.61-3.36c.72-.46,1.34-.45,1.95-.15l5.04,2.7c.5.3,1.51,1.2.5,1.8Z"
    isolation="isolate"
    opacity=".1"
  /><path d="m23.48,12.88c-.7-.4.4-1.1.4-2.6,0-1.1-.5-2.1-1.4-2.8-2.7-1.9-5.5-3.59-8.51-5.09-.7-.3-1.5-.4-2.3-.4-.5,0-1.22.13-1.62.33-1,.3-8.29,4.76-8.29,4.76-2.8,1.7-1.9,6.29.2,7.79,2.1,1.5,7.91,5.89,8.51,6.09,1.07.62,1.93.99,3.11,1.03,1.8,0,3.89-1.83,6.69-3.82,1.1-.8,2.2-1.6,3.2-2.6.3-.4,1-2,0-2.7Zm-1.2,1.7c-1,.92-1.97,1.74-3.13,2.56l-1.07.73c-1.28.92-2.56,1.89-3.55,2.38-.55.27-1.19.33-1.78.15-.35-.11-.68-.25-.98-.43-.2-.1-.4-.2-.6-.3-.5-.3-2.9-2-4.6-3.29-1.5-1.1-2.8-2.1-3.7-2.7-.9-.8-1.4-2-1.3-3.29,0-.8.4-1.5,1-1.9l.45-.32c3.3-2.2,6.95-4.18,7.65-4.48.3-.1.88-.15,1.18-.15.6,0,1.02.15,1.62.35,2.8,1.4,5.5,3,8.11,4.89h0c.5.4.8,1,.8,1.6,0,.3-.1.5-.2.8-.04.15-.09.26-.19.46-.1.3-.36.43-.64.64l-.27.19c-.6.5-3.9,2.8-6.81,4.79-.14.09-.26.14-.35-.04-.11-.43.09-.68.45-.96s4.29-3.12,6.58-4.63c.29-.23.42-.67.22-.97-.2-.3-.7-.4-1-.2-1,.7-6.11,3.99-6.91,4.89-.8.8-1.21,1.87-.38,2.97.59.58.78.63,1.18.63s.8-.2,1.1-.4c3-2.1,6.37-4.53,6.59-4.69s.51-.04.67.18c.07.11.06.32-.15.51Z" /></svg>
</template>
