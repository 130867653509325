import { type ProductType } from 'configs';

interface WishlistItem {
  objectId: string;
  productType: ProductType;
}

const LOCAL_STORAGE_KEY_WISHLIST = 'wishlist';

const useWishlist = () => {
  const wishlistItems = useLocalStorage<WishlistItem[]>(LOCAL_STORAGE_KEY_WISHLIST, []);

  const getWishlistItems = computed(() => wishlistItems.value);

  const setWishlistItems = (item: WishlistItem) => {
    const index = wishlistItems.value.findIndex((wishlistItem) => wishlistItem.objectId === item.objectId && wishlistItem.productType === item.productType);

    if (index === -1) {
      wishlistItems.value = [
        ...wishlistItems.value,
        item,
      ];
    } else {
      wishlistItems.value = wishlistItems.value.filter((wishlistItem) => !(wishlistItem.objectId === item.objectId && wishlistItem.productType === item.productType));
    }
  };

  const clearWishlistItems = () => {
    wishlistItems.value = [];
  };

  const isInWishlist = (itemToCheck: WishlistItem) => wishlistItems.value.some((item) => item.objectId === itemToCheck.objectId && item.productType === itemToCheck.productType);

  return {
    clearWishlistItems,
    getWishlistItems,
    isInWishlist,
    setWishlistItems,
  };
};

export { useWishlist };
