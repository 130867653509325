<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="M22.16,9.23c-.35-3.63-1-6-2.36-7.27A10.53,10.53,0,0,0,16.67.07a.73.73,0,0,0-.3-.06l-.15,0A33.13,33.13,0,0,1,12,.61,33.13,33.13,0,0,1,7.78,0L7.63,0a.73.73,0,0,0-.3.06A10.53,10.53,0,0,0,4.2,2c-1.32,1.26-2,3.64-2.36,7.27C1.6,11.79,1.21,14.4,1,15.7a.86.86,0,0,0,.73,1l1.61.22a.83.83,0,0,1,.73.76c0,.46,0,.86,0,.86a5.9,5.9,0,0,0,.62,3.32C5.59,23.17,7.35,24,12,24s6.41-.84,7.25-2.17a7.07,7.07,0,0,0,.74-3.5v-.62a.86.86,0,0,1,.74-.84l1.52-.2a.86.86,0,0,0,.73-1C22.79,14.4,22.4,11.79,22.16,9.23Zm-3.89,6.82-4.42,3.38h-3.7L5.73,16.05a18.6,18.6,0,0,0,1-6.3C6.24,4.86,7,3.1,7.56,2.48A4.8,4.8,0,0,0,12,5.31a4.56,4.56,0,0,0,4.36-2.9c.55.56,1.42,2.27.94,7.4A19.09,19.09,0,0,0,18.27,16.05ZM6.06,21.1,6,21a4.42,4.42,0,0,1-.39-2.35,1.27,1.27,0,0,0,0-.2s0-.29,0-.65l2,1.53ZM15,1.77a3.09,3.09,0,0,1-3,2A3.3,3.3,0,0,1,8.88,1.75,23.19,23.19,0,0,0,12,2.11,21.31,21.31,0,0,0,15,1.77ZM3.55,15.41l-1-.13c.22-1.44.53-3.68.75-5.91C3.66,6,4.28,4,5.24,3.05a5.17,5.17,0,0,1,.51-.42c-.67,1.57-.86,4-.55,7.2a17.1,17.1,0,0,1-.85,5.63.63.63,0,0,0-.21.12A2.24,2.24,0,0,0,3.55,15.41ZM7.3,22l1.48-1.65a0,0,0,0,0,0,0l.65.49a.74.74,0,0,0,.45.15h4.22a.74.74,0,0,0,.45-.15l.49-.37L16.5,22a15.56,15.56,0,0,1-4.5.48A14.29,14.29,0,0,1,7.3,22ZM18,21a2.65,2.65,0,0,1-.17.22l-1.58-1.77,2.26-1.72v.64A5.51,5.51,0,0,1,18,21Zm2.55-5.64a2.11,2.11,0,0,0-.66.2.61.61,0,0,0-.24-.15,17.65,17.65,0,0,1-.84-5.56c.31-3.26.12-5.69-.55-7.26a5.17,5.17,0,0,1,.51.42c1,.92,1.58,3,1.9,6.32.22,2.23.53,4.47.75,5.91Z" /><rect
    height="9"
    opacity="0.1"
    rx="1.34"
    width="7"
    x="8.5"
    y="7.14"
  /></svg>
</template>
