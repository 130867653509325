<script setup lang="ts">
import type { DesignType } from '#gql/default';
import type { BadgeVariants } from 'shared-components';

withDefaults(defineProps<{
  size?: BadgeVariants['size'];
  type: DesignType;
}>(), { size: 'xs' });

const translationKeys = {
  art: 'common.art',
  create: 'common.create',
};

const {
  closeDialog,
  isDialogOpen,
  openDialog,
} = useDialog();
</script>

<template>
  <div>
    <UiBadge
      :color="type === 'ART' ? 'dark' : 'orange'"
      :size="size"
      :title="type === 'ART' ? $t(translationKeys.art) : $t(translationKeys.create)"
      @click.prevent="openDialog"
    />

    <DesignTypeDialog
      :close-dialog="closeDialog"
      :is-dialog-open="isDialogOpen"
    />
  </div>
</template>
