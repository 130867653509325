import {
  framedProducts,
  productTypes,
} from 'configs/src/product-types';
import type { ProductType } from 'configs';

const PRODUCT_TYPE_WEIGHTS: Partial<Record<ProductType, number>> = {
  leinwand: 30,
  notebook: 10,
  'notebook-spiral': 10,
  poster: 30,
};

const productTypesWithoutFrames = productTypes.filter((productType) => !(framedProducts).includes(productType as typeof framedProducts[number]));

const getRandomUnframedProductType = (availableProductTypes: ProductType[]) => {
  const productTypesPool: ProductType[] = [];

  availableProductTypes.forEach((productType) => {
    const productTypeWeight = PRODUCT_TYPE_WEIGHTS[productType] ?? 1;
    const relativeWeight = Math.ceil(productTypeWeight * (availableProductTypes.length / productTypesWithoutFrames.length));

    for (let i = 0; i < relativeWeight; i += 1) {
      productTypesPool.push(productType);
    }
  });

  return getRandomArrayItem(productTypesPool);
};

export { getRandomUnframedProductType };
