<script setup lang="ts">
const props = defineProps<{
  closeDialog:() => void;
  isDialogOpen: boolean;
  isForCreators?: boolean;
}>();

const translationKeys = {
  art: 'designTypeDialog.art.title',
  artHeadline: 'designTypeDialog.art.headline',
  artSubline: 'designTypeDialog.art.subline',
  create: 'designTypeDialog.create.title',
  createHeadline: 'designTypeDialog.create.headline',
  createSubline: 'designTypeDialog.create.subline',
  creatorsArtHeadline: 'designTypeDialog.art.creatorsHeadline',
  creatorsArtSubline: 'designTypeDialog.art.creatorsSubline',
  creatorsCreateHeadline: 'designTypeDialog.create.creatorsHeadline',
  creatorsCreateSubline: 'designTypeDialog.create.creatorsSubline',
  creatorsHeadline: 'designTypeDialog.creatorsHeadline',
  creatorsSubline: 'designTypeDialog.creatorsSubline',
  headline: 'designTypeDialog.headline',
  subline: 'designTypeDialog.subline',
};

const isOpen = ref(props.isDialogOpen);

// Needed for multiple dialog usages at one page
watch(() => props.isDialogOpen, (newValue) => {
  isOpen.value = newValue;
});
</script>

<template>
  <div v-if="isOpen">
    <DialogRoot
      :open="isDialogOpen"
      @update:open="closeDialog"
    >
      <DialogContent>
        <div class="relative flex flex-col items-center">
          <p
            v-t="isForCreators ? translationKeys.creatorsHeadline : translationKeys.headline"
            class="text-xl font-bold md:text-3xl"
          />
          <p
            v-t="isForCreators ? translationKeys.creatorsSubline : translationKeys.subline"
            class="mb-8 mt-2.5 px-8 text-center text-sm md:px-16 md:text-base"
          />
          <div class="flex w-full flex-col gap-4 md:flex-row md:items-start">
            <div class="flex size-full flex-col items-center justify-start gap-4 overflow-hidden rounded-xl border border-grey p-6">
              <UiBadge
                color="orange"
                size="md"
                variant="solid"
                :title="$t(translationKeys.create)"
              />
              <img
                class="max-h-[150px] w-auto rounded-lg md:max-h-[180px]"
                src="./Image_Create.jpg"
              />
              <div class="flex w-full flex-col items-center justify-center text-center">
                <h3
                  v-t="isForCreators ? translationKeys.creatorsCreateHeadline : translationKeys.createHeadline"
                  class="m-0 text-xl font-black"
                />
                <p
                  v-t="isForCreators ? translationKeys.creatorsCreateSubline: translationKeys.createSubline"
                  class="mt-2 text-sm text-grey-semidark"
                />
              </div>
            </div>

            <div class="flex size-full flex-col items-center justify-start gap-4 overflow-hidden rounded-xl border border-grey p-6">
              <UiBadge
                color="dark"
                size="md"
                variant="solid"
                :title="$t(translationKeys.art)"
              />
              <img
                class="max-h-[150px] w-auto rounded-lg md:max-h-[180px]"
                src="./Image_Art.jpg"
              />
              <div class="flex w-full flex-col items-center justify-center text-center">
                <h3
                  v-t="isForCreators ? translationKeys.creatorsArtHeadline : translationKeys.artHeadline"
                  class="m-0 text-xl font-black"
                />
                <p
                  v-t="isForCreators ? translationKeys.creatorsArtSubline : translationKeys.artSubline"
                  class="mt-2 text-sm text-grey-semidark"
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogRoot>
  </div>
</template>
