<script setup lang="ts">
import { LayoutGridIcon } from 'lucide-vue-next';
import type { DesignType } from '#gql/default';

const AMOUNT_TO_SHOW = 4;
const THUMBNAIL_IMAGES_MAX = 4;
const GALLERY_IMAGES_MAX = 5;

const {
  getActiveIndex,
  mainCarousel,
  onGoToSlide,
  setActiveIndex,
  thumbnailCarousel,
} = useCarousel();

withDefaults(defineProps<{
  designType: DesignType;
  images: string[];
  isMatureContent: boolean;
  isThumbnailVisible: boolean;
}>(), {  })

const { getIsMatureContentEnabled } = useMatureContent();

const thumbnailImages = computed(() => __props.images.slice(0, THUMBNAIL_IMAGES_MAX));
const galleryImages = computed(() => __props.images.slice(0, GALLERY_IMAGES_MAX));

const visitedImageIndexes = new Set<number>();
watchEffect(() => visitedImageIndexes.add(getActiveIndex.value));

const moreToShow = computed(() => __props.images.length - AMOUNT_TO_SHOW > 0);

const shouldMatureContentBeBlurred = (imageIndex: number): boolean => !getIsMatureContentEnabled.value
  && __props.isMatureContent && imageIndex === getActiveIndex.value;
</script>

<template>
  <CarouselComponent
    ref="mainCarousel"
    class="product-tile-carousel overflow-hidden rounded-t-lg"
    :infinite="false"
    :nav-buttons="false"
    @before-change="setActiveIndex"
  >
    <div
      v-for="(item, itemIndex) in galleryImages"
      :key="itemIndex"
      class="product-tile-carousel__item flex aspect-[328/297] h-full items-center justify-center"
      :data-cy="`product-tile-carousel-${itemIndex}`"
    >
      <UiMatureButton
        v-if="shouldMatureContentBeBlurred(itemIndex)"
        show-button
      />
      <NuxtResponsivePicture
        show-loading-skeleton
        class="w-full"
        provider="cloudfront"
        :class="[shouldMatureContentBeBlurred(itemIndex) ? 'blur-xl' : 'blur-none']"
        :src="visitedImageIndexes.has(itemIndex) ? item : undefined"
        :width="329"
      />
    </div>
  </CarouselComponent>
  <div class="carousel-container absolute w-full -translate-y-1/2">
    <CarouselComponent
      v-if="isThumbnailVisible"
      ref="thumbnailCarousel"
      class="thumbnail-carousel mx-1.5 hidden rounded bg-grey-mid/60 bg-opacity-[94%] p-2.5 pb-4 backdrop-blur-md"
      :dots="false"
      :infinite="false"
      :nav-buttons="false"
      :use-as-navigation-for="mainCarousel ? [mainCarousel] : []"
    >
      <div
        v-for="(item, thumbIndex) in thumbnailImages"
        :key="thumbIndex"
        class="thumbnail-carousel__item-wrapper flex aspect-[5/4] items-center justify-center overflow-hidden rounded bg-white hover:cursor-pointer"
        @mouseenter="onGoToSlide(thumbIndex)"
        @touchend="onGoToSlide(thumbIndex)"
      >
        <UiMatureButton
          v-if="!getIsMatureContentEnabled && isMatureContent"
          icon-small
        />
        <NuxtResponsivePicture
          show-loading-skeleton
          class="thumbnail-carousel__image block aspect-[5/4] size-full rounded opacity-50"
          provider="cloudfront"
          :class="[
            { 'solid border border-dark !opacity-100': getActiveIndex === thumbIndex },
            !getIsMatureContentEnabled && isMatureContent ? 'blur-md' : 'blur-none'
          ]"
          :src="item"
          :width="60"
        />
      </div>
      <div
        v-if="moreToShow"
        class="thumbnail-carousel__item-wrapper thumbnail more-to-show flex aspect-[5/4] h-full items-center justify-center overflow-hidden rounded bg-white"
      >
        <LayoutGridIcon class="size-5 text-grey" />
      </div>
    </CarouselComponent>
    <DesignTypeBadge
      class="absolute bottom-0 right-4 translate-y-1/2 hover:cursor-pointer"
      :type="designType"
      @click.stop
    />
  </div>
</template>

<style lang="scss" scoped>
.product-tile-carousel {
  :deep(.carousel-component__list) {
    @apply h-full aspect-[327/297];
  }
}

.thumbnail-carousel {
  :deep(.carousel-component__track),
  :deep(.carousel-component__slides) {
    @apply w-full;
  }

  :deep(.carousel-component__slides) {
    @apply grid grid-cols-5 gap-x-2.5;
  }

  :deep(.carousel-component__list) {
    @apply overflow-visible;
  }
}
</style>
