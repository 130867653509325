import CarouselComponent from '@/components/shared/carousel-component/CarouselComponent.vue';
import type { FunctionVoid } from '@/types/common';
import type { Ref } from 'vue';

interface CarouselItem extends Ref {
  initCarousel: FunctionVoid;
  prepareCarousel: FunctionVoid;
}

const useCarousel = () => {
  const activeIndex = ref(0);
  const carouselWasInitialized = ref(false);

  const mainCarousel = ref<InstanceType<typeof CarouselComponent> | null>(null);
  const thumbnailCarousel = ref<InstanceType<typeof CarouselComponent> | null>(null);

  const getActiveIndex = computed(() => activeIndex.value);

  const setActiveIndex = (param: { nextSlide: number }) => {
    activeIndex.value = param.nextSlide;
  };

  const onGoToSlide = (thumbnailIndex: number) => {
    if (mainCarousel.value) {
      mainCarousel.value.goToSlide(thumbnailIndex);
    }
  };

  const { stop: stopIntersectionObserver } = useIntersectionObserver(
    mainCarousel as CarouselItem,
    ([ { isIntersecting } ]) => {
      if (isIntersecting
        && !carouselWasInitialized.value
        && mainCarousel.value) {
        mainCarousel.value.initCarousel();
        carouselWasInitialized.value = true;
      }
    },
  );

  const { stop: stopResizeObserver } = useResizeObserver(mainCarousel as CarouselItem, () => {
    if (mainCarousel.value && carouselWasInitialized.value) {
      mainCarousel.value.prepareCarousel();
    }
  });

  onBeforeUnmount(() => {
    stopIntersectionObserver();
    stopResizeObserver();
  });

  return {
    getActiveIndex,
    mainCarousel,
    onGoToSlide,
    setActiveIndex,
    thumbnailCarousel,
  };
};

export { useCarousel };
