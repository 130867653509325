import slugify from '@sindresorhus/slugify';
import {
  type ProductType,
  productTypeShortNames,
} from 'configs';
import {
  type ProductContext,
  type ProductContextKeys,
  productContextKeys,
} from '@/config/product-context';
import { State } from '@/config/state';
import type {
  ProductFromUrl,
  ProductTypeShort,
} from '@/types/product';
import type { RouteLocation } from 'vue-router';

interface CreateProductDetailPathParams {
  keepQueryParams?: boolean;
  product: {
    designId: string;
    designTitle?: string;
    type: ProductType;
  };
  productContext?: ProductContext;
}

const useProductDetail = () => {
  const route = useRoute('product-productType-product___uk');

  const router = useRouter();

  const { t } = useI18n();

  const localePath = useLocalePath();

  const { createProductContextWithDefaults } = useProductContext();

  const getActiveProduct = computed<ProductFromUrl | null>(() => {
    const { product } = route.params;

    const validProductTypePattern = Object.values(productTypeShortNames).map((str) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
      .join('|');

    const regex = new RegExp(`^(.*?)-?([0-9]+)-(${validProductTypePattern})$`);

    const match = product.match(regex);

    if (!match) {
      return null;
    }

    const [
      _,
      designTitle,
      designId,
      productTypeShort,
    ] = match;

    return {
      designId,
      designTitle,
      productTypeShort: productTypeShort as ProductTypeShort,
    };
  });

  const getProductTypeFromProductShortName = (productTypeShort: ProductTypeShort): ProductType => Object.keys(productTypeShortNames)
    .find((key) => productTypeShortNames[key as keyof typeof productTypeShortNames] === productTypeShort) as ProductType;

  const getActiveProductType = computed<ProductType | null>(() => (getActiveProduct.value?.productTypeShort
    ? getProductTypeFromProductShortName(getActiveProduct.value?.productTypeShort)
    : null));

  const createProductDetailPath = ({
    keepQueryParams = true,
    product,
    productContext,
  }: CreateProductDetailPathParams) => {
    const productTypeShort = productTypeShortNames[product.type];

    const designParts = [
      product.designTitle,
      product.designId,
      productTypeShort,
    ];

    const designString = slugify(designParts.filter(() => Boolean).join('-'));

    const queryParams = keepQueryParams
      ? route.query
      : {};

    return localePath({
      name: 'product-productType-product',
      params: {
        product: designString,
        productType: slugify(t(`product.type.${product.type}`)),
      },
      query: {
        ...productContext,
        ...queryParams,
      },
    } as unknown as RouteLocation);
  };

  const changeActiveProductType = (
    productType: ProductType,
    productOptions?: ProductContext,
    aspectRatio?: string,
    frameGroup?: string | null,
  ): void => {
    if (getActiveProduct.value) {
      const newUrl = createProductDetailPath({
        keepQueryParams: false,
        product: {
          designId: getActiveProduct.value.designId,
          ...(getActiveProduct.value.designTitle && { designTitle: getActiveProduct.value.designTitle }),
          type: productType,
        },
        productContext: createProductContextWithDefaults({
          aspectRatio,
          frameGroup,
          productOptions,
          productType,
        }),
      });

      router.replace(newUrl);
    }
  };

  const setContextQueryParam = (context: ProductContext) => {
    router.replace({
      query: {
        ...route.query,
        ...context,
      },
    });
  };

  const activeProductContext = useState<ProductContext>(State.ActiveProductContext, () => ({ }));

  const getActiveProductContext = computed<ProductContext>(() => activeProductContext.value);

  const setActiveProductContext = (contextType: ProductContextKeys, newValue: string | null): void => {
    activeProductContext.value[contextType] = newValue;
    setContextQueryParam(getActiveProductContext.value);
  };

  const setInitialProductWithContext = (productType: ProductType, options?: {
    aspectRatio?: string;
  }): void => {
    activeProductContext.value = {};

    const initialProductContextFromSearchParams: ProductContext = Object.fromEntries(productContextKeys.map((contextKey) => {
      const contextValue = route.query[contextKey];

      return [
        contextKey,
        (typeof contextValue === 'string'
          ? contextValue
          : ''),
      ];
    }));

    const initialProductContext = createProductContextWithDefaults({
      aspectRatio: options?.aspectRatio,
      productOptions: initialProductContextFromSearchParams,
      productType,
    });

    activeProductContext.value = {};

    Object.entries(initialProductContext).forEach(([
      optionKey,
      optionValue,
    ]) => {
      setActiveProductContext(optionKey as ProductContextKeys, optionValue);
    });
  };

  return {
    changeActiveProductType,
    createProductDetailPath,
    getActiveProduct,
    getActiveProductContext,
    getActiveProductType,
    getProductTypeFromProductShortName,
    setActiveProductContext,
    setInitialProductWithContext,
  };
};

export { useProductDetail };
