import {
  getMappingStandardFrame,
  type ProductType,
} from 'configs';
import {
  type ProductContext,
  productContextKeys,
} from '@/config/product-context';

type CreateProductContextParams = {
  aspectRatio?: string;
  frameGroup?: string | null;
  productOptions?: ProductContext;
  productType: ProductType;
};

const useProductContext = () => {
  const { getProductData } = useProductData();

  const createProductContextWithDefaults = ({
    aspectRatio,
    frameGroup,
    productOptions,
    productType,
  }: CreateProductContextParams): ProductContext => {
    const product = getProductData.value.find((productEntry) => productEntry.productType === productType);
    if (!product) {
      return {};
    }

    const defaultContext: ProductContext = Object.fromEntries(
      productContextKeys.map((productContextOption) => {
        let defaultValue = product[productContextOption].default;
        const { values } = product[productContextOption];

        if (productContextOption === 'frameGroup') {
          defaultValue = null;
        }

        if (productContextOption === 'size' && defaultValue && typeof defaultValue !== 'string') {
          const aspectRatioToSet = aspectRatio || product.aspectRatio?.default;

          defaultValue = getMappingStandardFrame(frameGroup, aspectRatio)?.default ?? (aspectRatioToSet
            ? product.size.default[aspectRatioToSet]
            : defaultValue[Object.keys(defaultValue)[0]]);
        }

        if (productContextOption === 'material' && values.length < 2) {
          defaultValue = null;
        }

        return ([
          productContextOption,
          defaultValue,
        ]);
      }),
    );

    const frameGroupValue = frameGroup && frameGroup.includes('-')
      ? frameGroup.split('-')[1]
      : frameGroup || '';

    return {
      ...(defaultContext && removeEmptyValuesFromObject(defaultContext)),
      ...(productOptions && removeEmptyValuesFromObject(productOptions)),
      ...(frameGroupValue && frameGroupValue !== 'default' && { frameGroup: frameGroupValue }),
    };
  };

  return { createProductContextWithDefaults };
};

export { useProductContext };
