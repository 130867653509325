<script setup lang="ts">
import { type ProductType } from 'configs';

const productTypeIconMap: Partial<Record<ProductType, () => unknown>> = {
  acrylglas: () => resolveComponent('AcrylglasIcon'),
  'acrylglas-gerahmt': () => resolveComponent('AcrylglasFrameIcon'),
  aludibond: () => resolveComponent('AludibondIcon'),
  'aludibond-gerahmt': () => resolveComponent('AludibondFrameIcon'),
  backlitfolie: () => resolveComponent('BacklitfolieIcon'),
  'bag-tote': () => resolveComponent('BagToteIcon'),
  'bib-baby': () => resolveComponent('BibBabyIcon'),
  'blanket-fleece': () => resolveComponent('BlanketFleeceIcon'),
  'curtain-shower': () => resolveComponent('CurtainShowerIcon'),
  'cushion-decorative': () => resolveComponent('CushionDecorativeIcon'),
  doormat: () => resolveComponent('DoormatIcon'),
  forexplatte: () => resolveComponent('ForexplatteIcon'),
  'forexplatte-gerahmt': () => resolveComponent('ForexplatteFrameIcon'),
  fototapete: () => resolveComponent('FototapeteIcon'),
  hahnemuehle: () => resolveComponent('HahnemuehleIcon'),
  'hahnemuehle-alurahmen': () => resolveComponent('HahnemuehleFrameIcon'),
  'hahnemuehle-holzrahmen': () => resolveComponent('HahnemuehleFrameIcon'),
  'hahnemuehle-kunststoffrahmen': () => resolveComponent('HahnemuehleFrameIcon'),
  hoodie: () => resolveComponent('HoodieIcon'),
  'hoodie-kid': () => resolveComponent('HoodieKidIcon'),
  leinwand: () => resolveComponent('LeinwandIcon'),
  'leinwand-schattenfuge': () => resolveComponent('LeinwandFrameIcon'),
  'mug-ceramic': () => resolveComponent('MugCeramicIcon'),
  'mug-enamel': () => resolveComponent('MugEnamelIcon'),
  notebook: () => resolveComponent('NotebookIcon'),
  'notebook-spiral': () => resolveComponent('NotebookSpiralIcon'),
  'phone-cover-iphone': () => resolveComponent('PhoneCoverIphoneIcon'),
  'phone-cover-samsung': () => resolveComponent('PhoneCoverSamsungIcon'),
  poster: () => resolveComponent('PosterIcon'),
  'poster-alurahmen': () => resolveComponent('PosterFrameIcon'),
  'poster-holzrahmen': () => resolveComponent('PosterFrameIcon'),
  'poster-kunststoffrahmen': () => resolveComponent('PosterFrameIcon'),
  pvcplane: () => resolveComponent('PvcplaneIcon'),
  'romper-long-sleeve-baby': () => resolveComponent('RomperLongSleeveBabyIcon'),
  'romper-short-sleeve-baby': () => resolveComponent('RomperShortSleeveBabyIcon'),
  'set-pillowcase-duvet-cover': () => resolveComponent('SetPillowDuvetCoverIcon'),
  sticker: () => resolveComponent('StickerIcon'),
  'sticker-sheet': () => resolveComponent('StickerSheetIcon'),
  sweatshirt: () => resolveComponent('SweatshirtIcon'),
  'sweatshirt-baby': () => resolveComponent('SweatshirtBabyIcon'),
  'sweatshirt-kid': () => resolveComponent('SweatshirtKidIcon'),
  't-shirt': () => resolveComponent('TshirtIcon'),
  't-shirt-baby': () => resolveComponent('TshirtBabyIcon'),
  't-shirt-fitted-woman': () => resolveComponent('TshirtFittedWomanIcon'),
  't-shirt-kid': () => resolveComponent('TshirtKidIcon'),
  'towel-bath': () => resolveComponent('TowelBathIcon'),
  'towel-beach': () => resolveComponent('TowelBeachIcon'),
  'towel-tea': () => resolveComponent('TowelTeaIcon'),
};

withDefaults(defineProps<{ productType: keyof typeof productTypeIconMap }>(), {  })

const iconComponent = computed(() => productTypeIconMap[__props.productType]?.() ?? null);
</script>

<template>
  <component :is="iconComponent" />
</template>
