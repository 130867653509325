enum PriceSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

type PriceSizeType = `${PriceSize}`;

type Price = {
  priceCurrent: number;
  priceOriginal: number;
};

export {
  type Price,
  PriceSize,
  type PriceSizeType,
};
