<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="M19.63,6.52c-.27,0-.52,0-.76,0V3.91h.06a1,1,0,0,0,1-1,1,1,0,0,0-1-1H1A1,1,0,0,0,0,3a1,1,0,0,0,1,1h.06v15.2S1.71,22,10,22s8.9-2.84,8.9-2.84v-2A6.14,6.14,0,0,0,20.39,17C21.89,16.52,24,15.55,24,11,24,7.26,21.54,6.52,19.63,6.52ZM10,20.5c-5,0-7-1.15-7.4-1.73V3.91h14.8V6.83h0V17.09h0v1.72C16.91,19.38,15,20.5,10,20.5Zm10-5a4.38,4.38,0,0,1-1.09.12V8.07A5.94,5.94,0,0,1,19.63,8c2,0,2.85.91,2.87,3C22.54,14.77,20.93,15.24,20,15.53Z" /><rect
    height="10"
    opacity="0.1"
    rx="1.93"
    width="10"
    x="4.86"
    y="7"
  /></svg>
</template>
