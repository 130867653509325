const removeEmptyValuesFromObject = (object: { [key: string]: any }): { [key: string]: any } => {
  const result: { [key: string]: any } = {};

  Object.entries(object).forEach(([
    key,
    value,
  ]) => {
    if (value) {
      result[key] = value;
    }
  });

  return result;
};

export { removeEmptyValuesFromObject };
