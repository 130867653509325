const productContextKeys = [
  'color',
  'frameGroup',
  'material',
  'size',
  'variant',
] as const;

type ProductContextKeys = typeof productContextKeys[number];

type ProductContext = Partial<Record<ProductContextKeys, string | null>>;

export {
  type ProductContext,
  productContextKeys,
  type ProductContextKeys,
};
