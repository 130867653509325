/* eslint-disable perfectionist/sort-objects */
const aspectRatio = [
  '4:3',
  '3:4',
  '3:2',
  '2:3',
  '4:5',
  '5:4',
  '1:1',
  '16:9',
  '9:16',
  '7:10',
  '3:1',
  '1:3',
  'din',
] as const;

type AspectRatio = typeof aspectRatio[number];
type FormatType = 'din' | 'square';

const formatTypeMapping = (type: string): string => {
  const mapping: Record<FormatType, string> = {
    din: '10:7',
    square: '1:1',
  };

  return type in mapping
    ? mapping[type as FormatType]
    : type;
};

const standardFrameMapping: Record<AspectRatio, { default: string; sizes: string[] } | null> = {
  '4:3': {
    default: '240x180',
    sizes: [
      '240x180',
      '400x300',
      '600x450',
      '800x600',
    ],
  },
  '3:4': {
    default: '180x240',
    sizes: [
      '180x240',
      '300x400',
      '450x600',
      '600x800',
    ],
  },
  '3:2': {
    default: '300x200',
    sizes: [
      '300x200',
      '450x300',
      '600x400',
      '900x600',
    ],
  },
  '2:3': {
    default: '200x300',
    sizes: [
      '200x300',
      '300x450',
      '400x600',
      '600x900',
    ],
  },
  '4:5': {
    default: '400x500',
    sizes: [ '400x500' ],
  },
  '5:4': {
    default: '500x400',
    sizes: [ '500x400' ],
  },
  '1:1': {
    default: '200x200',
    sizes: [
      '200x200',
      '300x300',
      '500x500',
    ],
  },
  '16:9': null,
  '9:16': null,
  '3:1': null,
  '1:3': null,
  din: {
    default: '297x210',
    sizes: [ '297x210' ],
  },
  '7:10': {
    default: '210x297',
    sizes: [ '210x297' ],
  },
};

const isAspectRatio = (key: string): key is AspectRatio => key in standardFrameMapping;

const getDefaultFrameSize = (aspectRatioParam: string) => {
  if (isAspectRatio(aspectRatioParam)) {
    return standardFrameMapping[aspectRatioParam] ?? null;
  }
  return null;
};

const STANDARD_FRAME = 'kunststoffrahmen';

const STANDARD_FRAMES_DISABLED_ASPECT_RATIO = [
  '16:9',
  '9:16',
  '3:1',
  '1:3',
];

// these sizes do only appear with standard frames
// https://docs.google.com/spreadsheets/d/12vk0nMR12L4fCMX1pxCN4XtPWYSPeq92xyTbHVcmIfw/edit?gid=258763934#gid=258763934
const STANDARD_FRAMES_SPECIAL_SIZES = [
  '240x180',
  '180x240',
  '148x105',
  '105x148',
];

const getMappingStandardFrame = (
  frameGroup: string | undefined | null,
  aspectRatioParam: string | undefined,
) => (frameGroup && frameGroup.includes(STANDARD_FRAME) && aspectRatioParam
  ? getDefaultFrameSize(aspectRatioParam)
  : null);

const frameMapping = (type: string | undefined | null) => {
  if (!type) {
    return undefined;
  }

  const mapping: Record<string, string> = {
    schattenfuge: 'schattenfuge-leinwand',
    gerahmt: 'artbox',
  };

  return type in mapping
    ? mapping[type]
    : type;
};

export {
  type AspectRatio,
  formatTypeMapping,
  frameMapping,
  getMappingStandardFrame,
  STANDARD_FRAME,
  STANDARD_FRAMES_DISABLED_ASPECT_RATIO,
  STANDARD_FRAMES_SPECIAL_SIZES,
  standardFrameMapping,
};
