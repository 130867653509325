<template>
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="M23.87,8.85A12.13,12.13,0,0,0,12.14,0,12.08,12.08,0,0,0,0,12,12.08,12.08,0,0,0,12.14,24H13a4.24,4.24,0,0,0,2.33-.48,14.21,14.21,0,0,0,4.73-4.27,25,25,0,0,0,2.62-4.41A12.45,12.45,0,0,0,24,9.45,1.78,1.78,0,0,0,23.87,8.85ZM1.48,12A10.59,10.59,0,0,1,12.14,1.5a10.73,10.73,0,0,1,8.32,3.94.61.61,0,0,0,.07.14c1,1.34,1.5,2.32,1.38,2.91s-1,1.06-2.08,1.65a12.11,12.11,0,0,0-4.28,3.32,8.14,8.14,0,0,0-1.37,5.33c0,1.19,0,2.13-.54,2.68s-1.68.81-3.48.81h-.08A10.55,10.55,0,0,1,1.48,12Zm17.41,6.36a12.56,12.56,0,0,1-3.71,3.52,6.23,6.23,0,0,0,.5-3.09,6.67,6.67,0,0,1,1.07-4.42,10.59,10.59,0,0,1,3.79-2.91,11,11,0,0,0,1.88-1.17,10.31,10.31,0,0,1-1.07,3.93A22.55,22.55,0,0,1,18.89,18.36Z" /><path
    d="M15.5,14.7a5.25,5.25,0,0,0-1.07,2.89A5.45,5.45,0,0,1,12,18a6,6,0,1,1,6-6c0,.54-1.69.43-1.82.92C16.18,12.92,16.55,13.63,15.5,14.7Z"
    opacity="0.1"
  /></svg>
</template>
